import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import HelpCalloutItem from '../HelpCalloutItem';
import PercentIconA from '../../images/A/percent-icon.svg';
import DollarIconA from '../../images/A/dollar-icon.svg';
import CheckIconA from '../../images/A/check-icon.svg';
import PercentIconB from '../../images/B/percent-icon.svg';
import DollarIconB from '../../images/B/dollar-icon.svg';
import CheckIconB from '../../images/B/check-icon.svg';
import './index.scss';

const HelpCallout = ({ theme }) => {
  const isDark = theme === 'dark';

  return (
    <div
      className={classNames(
        'HelpCallout',
        { 'u-bgColorDenim500': isDark },
        { 'HelpCallout--light u-bgColorWhite': !isDark }
      )}
    >
      <div
        className={classNames(
          'HelpCallout-header u-microText u-fontTextMedium',
          { 'u-fontTextBold': !isDark },
          { 'u-colorWhite': isDark },
          { 'u-colorDenim500': !isDark }
        )}
      >
        LendingClub could help you:
      </div>
      <HelpCalloutItem
        title="Pay down your high-interest debt"
        text="Make one easy monthly payment instead
        of juggling your credit card bills."
        icon={isDark ? PercentIconA : PercentIconB}
        classes="HelpCalloutItem--offsetBottom"
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
      <HelpCalloutItem
        title="Save money"
        text="Our members can save by consolidating high-interest debt to a Personal Loan."
        icon={isDark ? DollarIconA : DollarIconB}
        classes="HelpCalloutItem--offsetBottom"
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
      <HelpCalloutItem
        title="Check your rate in seconds"
        text="Check your rate in seconds, it won’t impact your credit score."
        icon={isDark ? CheckIconA : CheckIconB}
        titleClassNames={isDark ? 'u-colorSky500' : 'u-colorDenim500'}
        textClassNames={isDark ? 'u-colorWhite' : 'u-colorDenim500'}
      />
    </div>
  );
};

HelpCallout.propTypes = {
  theme: PropTypes.string,
};

export default HelpCallout;

