import { hasEqualValueInProp, toPercentage } from './index';

export default class OfferTagStrategyManager {
  constructor(filteredOffers = [], durations) {
    this._strategy = null;
    this._filteredOffers = filteredOffers;
    this._durations = durations;
    this._termWithLowestApr = null;
    this._tags = {};
    this._allTermsHaveSameApr = hasEqualValueInProp(filteredOffers, 'apr');
  }
  set strategy(strategy) {
    this._strategy = strategy;
  }
  get strategy() {
    return this._strategy;
  }

  getEmptyTags() {
    this._tags.bestTerm = this._filteredOffers[0].duration;
    return this._tags;
  }

  findOfferWithLowestApr() {
    if (hasEqualValueInProp(this._filteredOffers, 'apr')) return false;

    const offerWithLowestApr = this._filteredOffers.reduce((prev, curr) => (prev.apr < curr.apr ? prev : curr));
    const offersWithLowestAprCount = this._filteredOffers.reduce(
      (acc, curr) => (curr.apr === offerWithLowestApr.apr ? ++acc : acc),
      0
    );
    // if there are more than 1 offers with lowest apr, we don't change default labels
    if (offersWithLowestAprCount > 1) return false;
    return offerWithLowestApr;
  }

  calculateBestDefaultTerms() {
    const defaultTags = this._filteredOffers.reduce(
      (acc, term) => {
        if (!acc.bestTerm) {
          acc.bestTerm = term; // by default if theres no a term with lowest apr, shortest term will be the best term
          acc.smallestMonthlyPaymentTerm = term;
          return acc;
        }

        acc.bestTerm = term.apr < acc.bestTerm.apr ? term : acc.bestTerm;
        acc.smallestMonthlyPaymentTerm =
          +term.monthlyPayment < +acc.smallestMonthlyPaymentTerm.monthlyPayment ? term : acc.smallestMonthlyPaymentTerm;

        return acc;
      },
      {
        bestTerm: null, //term with lowest apr.
        smallestMonthlyPaymentTerm: null,
      }
    );

    const offersWithLowestAprCount = this._allTermsHaveSameApr
      ? this._filteredOffers.length
      : this._filteredOffers.reduce(
          (acc, curr) =>
            toPercentage(curr.apr, false) === toPercentage(defaultTags.bestTerm.apr, false) ? ++acc : acc,
          0
        );

    // if there are more than 1 offers with lowest apr, we don't change default labels
    this._termWithLowestApr = offersWithLowestAprCount > 1 ? false : defaultTags.bestTerm;

    this._termWithLowestAprTopUp = defaultTags.bestTerm;
    this._tags.bestTerm = defaultTags.bestTerm.duration;

    return defaultTags;
  }

  setDefaultTags() {
    this._strategy.setTagToShortestTerm({ filteredOffers: this._filteredOffers, tags: this._tags });
    const { smallestMonthlyPaymentTerm } = this.calculateBestDefaultTerms();

    this._smallestMonthlyPaymentTerm = smallestMonthlyPaymentTerm;
    this._strategy.setTagToSmallestMonthlyPaymentTerm({
      filteredOffers: this._filteredOffers,
      tags: this._tags,
      smallestMonthlyPaymentTerm,
    });
  }

  calculateOfferTags() {
    if (!this._filteredOffers || !this._filteredOffers.length) return {};
    if (this._filteredOffers.length === 1) return this.getEmptyTags();
    this.setDefaultTags();

    return this._strategy.calculateOfferTags({
      filteredOffers: this._filteredOffers,
      tags: this._tags,
      termWithLowerApr: this._termWithLowestApr,
      termWithLowerAprTopUp: this._termWithLowestAprTopUp,
      smallestMonthlyPaymentTerm: this._smallestMonthlyPaymentTerm,
    });
  }
}

