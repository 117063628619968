import { formatters } from 'ui-formatter';
import OfferTagStrategyManager from './OfferTagStrategyManager';
import OfferTermsTagStrategy from './OfferTagStrategy';
import MultipleOfferTermsTagStrategy from './MultipleOfferTagStrategy';
import TopUpOfferTagStrategy from './TopUpOfferTagStrategy';
import { format, isValid, parse } from 'date-fns';
import {
  KEY_PREFIX,
  ONT_COUPON_DISC_PAYLOAD_KEY_1,
  ONT_OFEE_DISC_PAYLOAD_KEY_1,
  ONT_OFEE_DISC_PAYLOAD_KEY_2,
  PA_OFEE_DISCOUNTED_V1,
  PRODUCT_OFFER_IDS,
  NON_CU_INVESTOR_IDS,
  PA_COUPON_DISCOUNTED_V1,
  COUPON,
  ONT_STATUS,
  PAYLOAD_MAP_TYPES,
} from '../../../baui-constants';
import { FLOW_TYPES } from '../../../prescreen-components';

const getLoanAmount = offer => offer.amount;

const getPaydownOffersByAmountAndTerm = (offers, filterAmount, duration) =>
  offers.filter(
    offer => getLoanAmount(offer) === filterAmount && offer.minDirectPayAmount > 0 && offer.duration === duration
  );

// This returns all offers for the LoanAmount filter. Incase of cash, it just returns all available terms offer for that amount
// Incase of BT, it further filters by the paydownAmount selected by borrower and returns all available terms offer.
const getFilteredOffers = (offers, filterAmount, paydownAmount, dpType, duration) => {
  // First filter offers based on the filterAmount
  const filteredOffersByAmount = offers.filter(
    offer => getLoanAmount(offer) === filterAmount && offer.dpType === dpType
  );
  let filteredOffers = filteredOffersByAmount;

  // If the toggle is turned on, we look for BT offers for the loanAmount selected.
  if (dpType === 'DIRECT_PAY_FOR_ALL') {
    // We will use bestBTOffer DP (direct pay/paydown) amount when there is no BT Offer for the paydownAmount passed from parent.
    // This will happen when borrower selects a paydownAmount from pop-up and changes the loanAmount to something else.
    // The new loanAmount may or may not have an offer with that paydownAmount so we default to bestBT offer in that case.
    const filteredOffersByPaydownAmount = filteredOffersByAmount.filter(
      offer => offer.minDirectPayAmount === paydownAmount
    );

    if (filteredOffersByPaydownAmount.length) {
      filteredOffers = filteredOffersByPaydownAmount;
    } else {
      // If no offers were found for that paydownAmount return the best BT offer ie the one with highest dp
      const bestBTOfferDPAmount = Math.max(...filteredOffersByAmount.map(offer => offer.minDirectPayAmount));
      filteredOffers = filteredOffersByAmount.filter(offer => offer.minDirectPayAmount === bestBTOfferDPAmount);
    }
    // ie missing duration filter for bt ( bug? )
  }

  // filter further more with loan duration
  if (duration) {
    filteredOffers = filteredOffers.filter(offer => offer.duration === duration);
  }

  // Default return Cash Offers
  return filteredOffers;
};

// appears to be similar to the above, just will also filter by duration for bt
// and does not default to best bt offer (=offer with lowest apr, which is different than above which is calculated by max minDirectPay) BY DEFAULT if no offer for the current paydown. added functionality to do this with options (ignored by dp type cash)
// TODO add tests
const filterOffers = (offers, filters, { useBestBTOffersIfPaydownNotFound = false } = {}) => {
  const { filterAmount, filterDuration, filterDpType, filterPaydown } = filters;
  const offersByAmountDurationType = offers.filter(
    offer =>
      (filterAmount === undefined || getLoanAmount(offer) === filterAmount) &&
      (filterDuration === undefined || offer.duration === filterDuration) &&
      (filterDpType === undefined || offer.dpType === filterDpType)
  );

  if (filterDpType === 'DIRECT_PAY_FOR_ALL') {
    let bestBTOffer = offersByAmountDurationType[0];
    const btOffersByPaydownAdditionally = offersByAmountDurationType.filter(offer => {
      if (offer.apr < bestBTOffer.apr) bestBTOffer = offer; // piggyback so not another iterator
      return filterPaydown === undefined || offer.minDirectPayAmount === filterPaydown;
    });
    if (btOffersByPaydownAdditionally.length || !useBestBTOffersIfPaydownNotFound) return btOffersByPaydownAdditionally;
    // if no offers found for the paydown passed and the optional fallback flag is on, return the offers that have the same paydown as the best bt offer
    return btOffersByPaydownAdditionally.filter(offer => offer.minDirectPayAmount === bestBTOffer.minDirectPayAmount);
  }

  return offersByAmountDurationType;
};

const getOfferById = (offers, id) => offers.filter(offer => offer.id.toString() === id);

// sorted, available amounts
const getAvailableAmounts = offers =>
  offers // show all available amounts
    .map(offer => getLoanAmount(offer))
    .filter((element, index, self) => self.indexOf(element) === index) // dedup
    .sort((a, b) => a - b);

const getOffersByTermAndAmount = (offers, filterDuration, filterAmount) =>
  offers
    .filter(offer => getLoanAmount(offer) === filterAmount)
    .filter(offer => offer.duration === filterDuration)
    .sort((a, b) => a.apr - b.apr);

const sortOffersByProp = (offers, prop) => offers.sort((a, b) => a[prop] - b[prop]);

const getBestOfferByDuration = offers => sortOffersByProp(offers, 'duration')[0];

const getBestOffer = (offers, amount) => getBestOfferByDuration(filterOffers(offers, { amount }));

const getSelectedOffer = (offers, selectedOfferType, selectedOfferId) => {
  if (selectedOfferType === 'CASH') {
    return offers.cashOffers.filter(offer => offer.id === selectedOfferId)[0];
  }

  return offers.allBTOffers.filter(offer => offer.id === selectedOfferId)[0];
};

const toPercentage = (value, withPercentageSign = true) =>
  `${parseFloat(value * 100).toFixed(2)}${withPercentageSign ? '%' : ''}`;

const toPercentageNumber = (value = 0) => parseFloat((value * 100).toFixed(2));

const toPercentageTruncZero = (value, withPercentageSign = true) => {
  const percentageSign = withPercentageSign ? '%' : '';
  const formattedPercentage = `${parseFloat((value * 100).toString()).toFixed(2)}${percentageSign}`;
  if (formattedPercentage.indexOf('.') !== -1) {
    const splittedValue = formattedPercentage.split('.');
    return splittedValue[1].indexOf('00') !== -1 ? `${splittedValue[0]}${percentageSign}` : formattedPercentage;
  }
  return formattedPercentage;
};

/**
 * @description formats a number to percentage with one decimal digit
 * @param {number} value number to format
 * @returns {string}
 */
const toPercentageTruncZeroOneDecimal = value => {
  let percentageValue = toPercentageTruncZero(value, false);

  if (percentageValue.indexOf('.') !== -1) {
    const values = percentageValue.split('.');
    percentageValue = values[1].startsWith('0') ? values[0] : parseFloat(percentageValue).toFixed(1);
  }
  return `${percentageValue}%`;
};

/**
 * Converts numbers to formatted money values. Truncates zeros from dollar amounts without change
 * @param {number} value
 * @returns {string}
 */
const toMoneyTruncZero = value => {
  const moneyValue = formatters.toMoney.bind({ precision: 2, roundFn: 'round' })(value.toString());
  if (moneyValue.indexOf('.') !== -1) {
    const decimals = moneyValue.split('.');
    return decimals[1] === '00' ? decimals[0] : moneyValue;
  }
  return moneyValue;
};

const toMoney = (value, options) =>
  formatters.toMoney.bind({ precision: 2, roundFn: 'round', ...options })(value.toString());

// only expose required fields, this should eventually move to server side
const mapOffers = offers =>
  offers.map(offer => ({
    duration: offer.duration,
    monthlyPayment: offer.monthlyPayment,
    interestRate: offer.interestRate,
    apr: offer.apr,
    amount: offer.amount,
    originationFeeAmt: offer.originationFeeAmt,
    originationFeeRate: offer.originationFeeRate,
    cashAmount: offer.cashAmount,
    id: offer.id,
    dpType: offer.dpType,
    minDirectPayAmount: offer.minDirectPayAmount,
  }));

const getOfferCountByDuration = (offers, duration) => offers.filter(offer => offer.duration === duration).length;

const getOffersDuration = (offers, durations) =>
  getOfferCountByDuration(offers, durations[0]) > 0
    ? durations[0]
    : durations.length > 2 && getOfferCountByDuration(offers, durations[1]) > 0
    ? durations[1]
    : 60;

const defaultTerms = [36, 60];
const findOfferByDuration = (offers, duration) => offers.find(offer => offer.duration === duration);
const findTermByDuration = (terms, duration) => terms.find(term => term.duration === duration);

/**
 * Loan Term Expansion - get current terms information in order to display best term against other terms
 *
 * @function
 *
 * @param  {Object} offers - available offers
 * @param  {Array}  durations - available loan term durations
 *
 * @return  {Object} Current Term information filter by best/rest of terms calculation
 */
const getOffersByTerm = (offers, durations) => {
  const allTerms = durations.map(duration => ({
    duration,
    offer: findOfferByDuration(offers, duration),
    isDefaultTerm: defaultTerms.includes(duration),
  }));

  const newDurationsObj = allTerms.reduce(
    (acc, term) => {
      if (term.offer) {
        acc.available.push(term.duration);
      } else {
        acc.missing.push(term.duration);
      }
      return acc;
    },
    {
      missing: [],
      available: [],
    }
  );
  const [bestTerm, ...restTerms] = allTerms;

  const unavailableTerms = restTerms.flatMap(term => (term.offer ? [] : term.duration));

  return {
    bestTerm,
    compareTerms: restTerms,
    unavailableTerms,
    missingDurations: newDurationsObj.missing,
    availableDurations: newDurationsObj.available,
    missingDurationsCount: newDurationsObj.missing.length,
    availableDurationsCount: newDurationsObj.available.length,
  };
};

const getDefaultCompareTerm = (bestTerm, compareTerm, compareTerms) => {
  switch (bestTerm.duration) {
    case 24:
      compareTerm = findTermByDuration(compareTerms, 60);
      if (!compareTerm.offer) compareTerm = findTermByDuration(compareTerms, 36);
      break;
    case 36:
      compareTerm = findTermByDuration(compareTerms, 48);
      if (!compareTerm.offer) compareTerm = findTermByDuration(compareTerms, 60);
      break;
    default:
      break;
  }
  return compareTerm;
};

/**
 * Loan Term Expansion - Filter current term to compare.
 *
 * @function
 *
 * @param  {Object} termInfo - terms object calculation from getOffersByTerm method + filterDuration and isBT.
 * @param  {Object} termInfo.bestTerm - best term object available in durations terms at position zero.
 * @param  {Array}  termInfo.compareTerms - rest of available durations
 * @param  {Array}  termInfo.unavailableTerms - terms array without offer information
 * @param  {number} termInfo.filterDuration - current filter duration
 * @param  {boolean} ignoreSelection - boolean to identify 1st time page load to override the default selection for 24 => 60 && 36 => 48
 *
 * @return  {Object|null} Current term to compare
 */
const getCompareTerm = ({ bestTerm, compareTerms, unavailableTerms, filterDuration }, ignoreSelection) => {
  if (unavailableTerms.length >= compareTerms.length) return;
  let compareTerm;

  if (!ignoreSelection && filterDuration !== bestTerm.duration)
    compareTerm = findTermByDuration(compareTerms, filterDuration);
  if (!compareTerm?.offer) compareTerm = getDefaultCompareTerm(bestTerm, compareTerm, compareTerms);

  if (compareTerm) {
    compareTerm.label = compareTerm.duration === 60 ? 'Lowest Payment' : 'Lower Payment';
  }
  return compareTerm;
};

/**
 * @description find an offer pair by duration/amount and check if both offers have same Prop Value
 * @param {Object} params
 * @param {Array=} params.offers list of offers where to look for the pair offer
 * @param {Object} params.targetOffer CASH/BT offer
 * @param {String=} params.prop offer prop to compare
 */
const findMatchingOffer = ({ offers = [], targetOffer, prop = 'apr' }) => {
  const matchingOffer = offers?.find(
    offer => targetOffer.amount === offer.amount && offer.duration === targetOffer.duration
  );
  return {
    offer: matchingOffer,
    targetOffer,
    offerList: matchingOffer ? [targetOffer, matchingOffer] : [targetOffer],
    hasSameValue: matchingOffer && matchingOffer[prop] === targetOffer[prop],
    hasHigherThanTarget: matchingOffer && matchingOffer[prop] > targetOffer[prop],
  };
};

export const calculateSavings = ({ btOffer, cashOffer }) => {
  if (!btOffer || !cashOffer) return;
  return Math.round((cashOffer.monthlyPayment - btOffer.monthlyPayment) * btOffer.duration);
};

export const getCashOffer = ({ offer, filteredCashOffers }) => {
  const matchingOffer = findMatchingOffer({ offers: filteredCashOffers, targetOffer: offer });
  // cash offer Apr < bt Apr, this is not a possible scenario, but we are validating it
  return matchingOffer?.hasSameValue || matchingOffer?.offer?.apr < offer.apr ? null : matchingOffer?.offer;
};

const OFFER_TYPES = {
  BT: 'DIRECT_PAY_FOR_ALL',
  CASH: 'CASH',
};

const isBTOffer = dpType => dpType === OFFER_TYPES.BT;

const tagsStrategies = {
  default: OfferTermsTagStrategy,
  multiple: MultipleOfferTermsTagStrategy,
  topup: TopUpOfferTagStrategy,
};

function getOfferTags(filteredOffers, durations, subProduct) {
  const offerTagStrategyManager = new OfferTagStrategyManager(filteredOffers, durations);
  const selectedStrategy = subProduct === FLOW_TYPES.TOPUP ? subProduct : durations.length > 2 ? 'multiple' : 'default';
  offerTagStrategyManager.strategy = new tagsStrategies[selectedStrategy]();

  return offerTagStrategyManager.calculateOfferTags();
}

const hasEqualValueInProp = (arr, key) => [...new Set(arr.map(item => item[key]))].length === 1;

const OFFER_CARD_LABELS = {
  LOWER_PAYMENT: 'Lower Payment',
  LOWEST_PAYMENT: 'Lowest Payment',
  FASTEST_PAYOFF: 'Fastest Pay-Off',
  FASTER_PAYOFF: 'Faster Pay-Off',
  BETTER_RATE: 'Better Rate',
  BEST_RATE: 'Best Rate',
  BEST_RATE_AND_LOWEST_PAYMENT: 'Best Rate & Lowest Payment',
};

/**
 * Add custom props to handle offer card labels
 * @param  {Object} filteredOffers - available offers
 * @param  {Array}  durations - available loan term durations
 * @param  {String=} subProduct - subproduct
 * @returns {Array} offers array
 */
const richOffers = (filteredOffers, durations, subProduct = undefined) => {
  const cardLabels = getOfferTags(filteredOffers, durations, subProduct);
  return filteredOffers.map(offer => ({
    ...offer,
    label: cardLabels[offer.duration],
    isBestTerm: offer.duration === cardLabels?.bestTerm,
    className: offer.duration === cardLabels?.bestTerm ? 'bestTerm' : 'compareTerm',
  }));
};

export const buildTrackingLowOFee = (isLowOFee, cb) => {
  let props = {};
  if (isLowOFee) props = cb(props);
  return props;
};

export const isCreditUnionOffer = currentSelectedOffer =>
  !!PRODUCT_OFFER_IDS[currentSelectedOffer?.productId] &&
  currentSelectedOffer.preAssignedInvestorId &&
  !NON_CU_INVESTOR_IDS[currentSelectedOffer.preAssignedInvestorId];

const getKey = payloadMap => {
  if (payloadMap.hasOwnProperty(ONT_COUPON_DISC_PAYLOAD_KEY_1)) return ONT_COUPON_DISC_PAYLOAD_KEY_1;
  if (payloadMap.hasOwnProperty(ONT_OFEE_DISC_PAYLOAD_KEY_1)) return ONT_OFEE_DISC_PAYLOAD_KEY_1;
  if (payloadMap.hasOwnProperty(ONT_OFEE_DISC_PAYLOAD_KEY_2)) return ONT_OFEE_DISC_PAYLOAD_KEY_2;
  if (payloadMap.hasOwnProperty(PA_COUPON_DISCOUNTED_V1)) return PA_COUPON_DISCOUNTED_V1;
  if (payloadMap.hasOwnProperty(PA_OFEE_DISCOUNTED_V1)) return PA_OFEE_DISCOUNTED_V1;
};

const isONTCouponDiscount = ontData =>
  ontData?.subtype === COUPON && ontData?.status === ONT_STATUS.DISCOUNTED && ontData?.isSupportedOntVersion;

const getOfferSetPayloadMap = payloadMap => {
  if (!payloadMap) return;
  const key = getKey(payloadMap);
  const keyPrefix = KEY_PREFIX[key] || '';
  const payload = JSON.parse(payloadMap[key] || '{}');
  return { ...payload, type: PAYLOAD_MAP_TYPES[key], keyPrefix };
};

export const getOfferSetPayloadMapped = payloadMapped => {
  if (!payloadMapped) return;
  const key = getKey(payloadMapped);
  const keyPrefix = KEY_PREFIX[key] || '';
  return { ...payloadMapped[key], keyPrefix };
};

/**
 * @description creates an array with the selected offer and another with payloadMap data
 * @param {Object} currentOffer selected offer
 * @param {Object=} cashPairOffer matching cash offer
 * @returns {Array} offers [offerDataFromONTPayloadMap, selectedOffer]
 */
const getTooltipCouponOffers = (currentOffer, cashPairOffer) => {
  const payloadMap = getOfferSetPayloadMap(cashPairOffer ? cashPairOffer.payloadMap : currentOffer.payloadMap);
  return [
    {
      apr: payloadMap[`apr_pct_before_${payloadMap?.keyPrefix}disc`],
      duration: currentOffer.duration,
    },
    currentOffer,
  ];
};

const getOfferColumns = (isCoupon, payloadMap, cashOffer, finalOffer) => {
  const payload = isCoupon && getOfferSetPayloadMap(cashOffer ? cashOffer.payloadMap : payloadMap);
  const prefix = payload?.keyPrefix;
  const couponOffer = payload && {
    duration: finalOffer.duration,
    interestRate: payload[`${prefix}pct_before_disc`],
    apr: payload[`apr_pct_before_${prefix}disc`],
    monthlyPayment: payload[`${prefix}payment_amt_before_disc`],
  };
  return {
    offer: { ...finalOffer },
    ...(couponOffer ? { discountOffer: couponOffer } : cashOffer ? { discountOffer: cashOffer } : null),
  };
};

const isPairOfferAvailable = (offers, selectedOffer) =>
  offers.find(offer => selectedOffer.amount === offer.amount && offer.duration === selectedOffer.duration);

export const toDate = value => {
  const parsed = parse(value, 'MM/DD/YYYY', new Date());
  if (isValid(parsed)) return format(value, 'MM/DD/YYYY');
  return false;
};

export const maskValue = (value, numVisibleCharacters) => {
  value = value.toString();
  const maskedPart = '*'.repeat(value.length - numVisibleCharacters);
  const visiblePart = value.slice(-numVisibleCharacters);
  const maskedValue = maskedPart.concat(visiblePart);
  return maskedValue;
};

// Note: currently we only support CASH and BT offers, if we want to support other types, this condition must be updated.
const findPairOffer = (offers, selectedOffer) =>
  offers.find(
    offer =>
      offer.amount === selectedOffer.amount &&
      offer.duration === selectedOffer.duration &&
      offer.dpType !== selectedOffer.dpType
  );

export {
  findPairOffer,
  getLoanAmount,
  filterOffers,
  getAvailableAmounts,
  getOffersByTermAndAmount,
  getSelectedOffer,
  mapOffers,
  toPercentage,
  toPercentageNumber,
  toPercentageTruncZero,
  toMoney,
  toMoneyTruncZero,
  getPaydownOffersByAmountAndTerm,
  getOfferById,
  getFilteredOffers,
  getOffersDuration,
  getOffersByTerm,
  getCompareTerm,
  getBestOffer,
  isBTOffer,
  richOffers,
  OFFER_TYPES,
  OFFER_CARD_LABELS,
  hasEqualValueInProp,
  sortOffersByProp,
  findMatchingOffer,
  toPercentageTruncZeroOneDecimal,
  getKey,
  getOfferSetPayloadMap,
  getTooltipCouponOffers,
  getOfferColumns,
  isPairOfferAvailable,
  isONTCouponDiscount,
};

