import React, { useState, useEffect } from 'react';
import axios from 'lc-http';
import { heapTrackEvent } from '../../../shared_modules/baui-heap-tracking';
import { isAlphaNumeric } from '../../../shared_modules/baui-utils/client/validation/validators';

import {
  DM_CODE_SUBMIT_URL,
  DM_MI_CODE_SUBMIT_URL,
  DM_CODE_MAX_LENGTH,
  MAX_INVALID_DM_CODE_ATTEMPTS,
  DM_CODE_INVALID,
  DM_CODE_NOT_ALPHANUMERIC,
  DM_ERROR_UNEXPECTED,
  DM_ERROR_CODE_LENGTH,
} from './constants/dmCode';

import Header from './components/Header';
import Footer from './components/Footer';
import Layout from './layouts/Layout';

import Logo from './images/lc-logo.svg';

import '../direct-mail.scss';

const App = () => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [invalidCodeCount, setInvalidCodeCount] = useState(0);
  const [startTime] = useState(Date.now());
  const attemptErrors = [DM_CODE_INVALID];

  let flowDetails = {
    flow: 'DM',
    heapEventName: 'PL DM Code',
    queryParams: '',
    type: 'DIRECT_MAIL',
    url: DM_CODE_SUBMIT_URL,
  };

  const isNewMemberInviteFlow = window.location.pathname.includes('member-invite');
  if (isNewMemberInviteFlow) {
    const qs = new URLSearchParams(window.location.search);
    flowDetails = {
      ...flowDetails,
      flow: 'MEMBER_INVITE_DM',
      heapEventName: 'PL Member Invite Code',
      queryParams: `?${qs.toString()}`,
      url: DM_MI_CODE_SUBMIT_URL,
    };
  }

  useEffect(() => {
    if (invalidCodeCount >= MAX_INVALID_DM_CODE_ATTEMPTS) {
      heapTrackEvent(`${flowDetails.heapEventName} Submit Attempt`, {
        errMessage: 'too many attempts',
        flow: flowDetails.flow,
      });
    }
  }, [invalidCodeCount]);

  const fireHeapEvent = (eventName, nextPage, flow) => {
    heapTrackEvent(`${eventName} - click check your rate cta`, { nextPage, invalidCodeCount, flow });
    heapTrackEvent(`${eventName} - time on page`, {
      timeOnPageInSec: (Date.now() - startTime) / 1000,
      flow,
    });
  };

  const handleDMCodeSubmit = fundingCode => {
    const { heapEventName, flow, url, queryParams } = flowDetails;
    if (fundingCode.length === DM_CODE_MAX_LENGTH) {
      if (!isAlphaNumeric(fundingCode)) {
        setInvalidCodeCount(invalidCodeCount + 1);
        return setSubmitStatus({ error: DM_CODE_NOT_ALPHANUMERIC });
      }
      heapTrackEvent(`${heapEventName} Submit Attempt`, { fundingCode, flow });
      axios
        .post(`${url}${queryParams}`, { fundingCode })
        .then(res => {
          const { data } = res;
          const { code, err, nextPage } = data;
          if (err) {
            err.forEach(err => {
              if (attemptErrors.includes(err.error)) setInvalidCodeCount(invalidCodeCount + 1);
            });

            // response has returned with error, so send the first one
            // we see.
            setSubmitStatus({ error: err[0] });
          }

          if (nextPage) {
            // check to see if the next page directs us to the pi funnel
            // to pre-populate the form
            if (nextPage.startsWith('/apply/personal/identity')) {
              // create a form so we can piggy back on the default
              // submit behavior so we can redirect the user to the
              // pi funnel with the given funding code so we can pre-fill the
              // flow
              const form = document.createElement('form');
              // set the form to redirect submit the data and redirect
              // to the correct url
              form.action = nextPage;
              form.method = 'POST';
              // add the form to the page
              document.body.appendChild(form);

              // create a hidden input field that will contain the funding code
              const codeInput = document.createElement('input');
              codeInput.type = 'hidden';
              codeInput.name = 'fundingCode';
              codeInput.value = code;
              // add the input to the form
              form.appendChild(codeInput);
              // sumbit the form and redirect.
              fireHeapEvent(heapEventName, nextPage, flow);
              form.submit();
            } else {
              // then the code has gone though,
              // redirect to the next page
              fireHeapEvent(heapEventName, nextPage, flow);
              window.location.assign(nextPage);
            }
          }
        })
        .catch(err => {
          // TODO: get correct verbage for this and
          // find out if there some kind of error logger we can use.
          setSubmitStatus({
            error: DM_ERROR_UNEXPECTED,
          });
        });
    } else {
      // TODO: get correct verbage for this and
      // find out if there some kind of error logger we can use.
      heapTrackEvent(`${heapEventName} Submit Attempt`, { errMessage: 'invalid code length' });
      setSubmitStatus({
        error: DM_ERROR_CODE_LENGTH,
      });
    }
  };

  return (
    <div className="DirectMail u-positionRelative">
      <Header logoImgSrc={Logo} logoAltText="LendingClub logo" classes="u-positionAbsolute" />
      <Layout onDMCodeSubmitHandler={handleDMCodeSubmit} submitStatus={submitStatus} />
      <Footer />
    </div>
  );
};

export default App;

