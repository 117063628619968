const DM_PAGE_CONTENT_MAP = {
  HEADLINE: {
    control: 'Lock in a competitive fixed rate today and start paying down debt fast',
  },
  BODY: {
    control:
      'Join over 5 million members who have used LendingClub to pay down debt, refinance credit cards, build savings, and reach their financial goals.',
  },
  SUBHEAD: {
    control: "Checking your rate won't affect your credit score",
  },
  CTA_TEXT: {
    control: 'Check Your Rate',
  },
};

export const DM_PAGE_CONTENT_DEFAULT = {
  headline: DM_PAGE_CONTENT_MAP.HEADLINE.control,
  body: DM_PAGE_CONTENT_MAP.BODY.control,
  ctaText: DM_PAGE_CONTENT_MAP.CTA_TEXT.control,
  subhead: DM_PAGE_CONTENT_MAP.SUBHEAD.control,
  hasSup: true,
};

