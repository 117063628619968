// static version the the review widget
// this will be replaced later with a version that
// pulls live data
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { heapTrackEvent } from '@shared_modules/baui-heap-tracking';
import './index.scss';
import trustPilotLogo from './images/TrustPilot-stars-4.7.svg';
import { TP_URL } from '../../constants/userResourceUrls';

const TrustPilotWidget = ({ classes }) => {
  return (
    <div
      className={classnames('TrustPilotWidget u-textAlignCenter', classes)}
      onClick={() => heapTrackEvent('PL DM - click trust pilot link')}
    >
      <a className="u-textDecorationNone" href={TP_URL} target="_blank" rel="noopener noreferrer">
        <img className="TrustPilotWidget-logo" src={trustPilotLogo} alt="trust-pilot-rating" />
        <div className="TrustPilotWidget-reviews">5k+ Reviews</div>
      </a>
    </div>
  );
};

TrustPilotWidget.propTypes = {
  classes: PropTypes.string,
};

export default TrustPilotWidget;

