import React from 'react';
import { bool, func, node } from 'prop-types';
import { Button } from '@shared_modules/local-propulsion/components';
import { Grid } from 'propulsion-rc-v2';
import Modal from '@shared_modules/rc-modal-v2/Modal';
import './index.scss';

const ROOT_CLASS = 'FundingCodeInvalidModal';

const FundingCodeInvalidModal = ({ handleClose, handleCTAClick, showModal, children }) => {
  return (
    <Modal
      aria-labelledby={`${ROOT_CLASS}-funding-error-modal`}
      showModal={showModal}
      showCloseIcon
      handleClose={handleClose}
      ariaHideApp={false}
    >
      <Grid container className={`${ROOT_CLASS}-body`}>
        {children}
        <Grid item className={`${ROOT_CLASS}-actions`}>
          <Button
            variant="primary"
            color="red"
            tabIndex="0"
            name="submit-direct-home"
            className={`${ROOT_CLASS}-startApplication`}
            onClick={handleCTAClick}
          >
            Start a New Application
          </Button>
          <div className={`${ROOT_CLASS}-subTextOr`}>—OR—</div>
          <Button
            variant="tertiary"
            tabIndex="0"
            name="button-try-other-code"
            className={`${ROOT_CLASS}-tryOtherCode`}
            onClick={handleClose}
          >
            Try a Different RSVP Code
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

FundingCodeInvalidModal.propTypes = {
  handleClose: func.isRequired,
  children: node,
  handleCTAClick: func.isRequired,
  showModal: bool,
};

FundingCodeInvalidModal.defaultProps = { showModal: false };

export default FundingCodeInvalidModal;

